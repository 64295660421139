import React, { useMemo } from 'react';
// const MicrophoneSVG = ({scale}) => {
//   return (
//     <svg width={scale ? "100" : "100"} height={scale ? "100" : "300"} viewBox="0 0 100 300" xmlns="http://www.w3.org/2000/svg">
//       <polygon points="30,120 70,120 65,240 35,240" fill="#333" />
//       <circle cx="50" cy="100" r="30" fill="#bbb" />
//       <text x="50%" y="30" fill="black" textAnchor="middle" fontSize="30" fontFamily="Arial" dy=".3em">マイク</text>
//     </svg>
//   );
// }

const TelevisionSVG = ({scale}) => {
  return (
    <svg width={scale ? "100" : "200"} height={scale ? "100" : "150"} viewBox="0 0 200 150" xmlns="http://www.w3.org/2000/svg">
      <rect x="10" y="30" width="180" height="100" fill="black" rx="10" ry="10"/>
      <rect x="20" y="40" width="160" height="80" rx="10" ry="10" fill="lightblue" />
      <rect x="90" y="130" width="20" height="15" fill="black"/>
      <ellipse cx="100" cy="145" rx="30" ry="10" fill="black"/>
        <text x="50%" y="10" fill="black" textAnchor="middle" fontSize="20" fontFamily="Arial" dy=".3em">テレビ</text>
    </svg>
  );
}

const MonitorSVG = ({scale}) => {
  return(
    <svg width={scale ? "100" : "200"} height={scale ? "100" : "150"} viewBox="0 0 200 150" xmlns="http://www.w3.org/2000/svg">
      <rect x="10" y="30" width="180" height="100" fill="black" rx="10" ry="10"/>
      <rect x="20" y="40" width="160" height="80" rx="10" ry="10" fill="lightblue" />
      <rect x="90" y="130" width="20" height="15" fill="black"/>
      <ellipse cx="100" cy="145" rx="30" ry="10" fill="black"/>
        <text x="50%" y="10" fill="black" textAnchor="middle" fontSize="20" fontFamily="Arial" dy=".3em">モニター</text>
    </svg>
  )
}

const MixerSVG = ({scale}) => {
  return(
    <svg width={scale ? "100" : "100"} height={scale ? "100" : "100"} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <rect x="10" y="10" width="80" height="80" rx="5" ry="5" fill="white" stroke="black" />
    <rect x="15" y="15" width="20" height="20" rx="3" ry="3" fill="black" />
    <rect x="40" y="15" width="45" height="10" rx="2" ry="2" fill="black" />
    <rect x="40" y="30" width="45" height="5" rx="2" ry="2" fill="black" />
    
    <circle cx="25" cy="50" r="5" fill="black" />
    <circle cx="45" cy="50" r="5" fill="black" />
    <circle cx="65" cy="50" r="5" fill="black" />
    
    <rect x="15" y="60" width="70" height="5" fill="black" />
    <rect x="15" y="70" width="70" height="5" fill="black" />
    <rect x="15" y="80" width="70" height="5" fill="black" />
    
    <text x="50%" y="5" fill="black" textAnchor="middle" fontSize="10" fontFamily="Arial" dy=".3em">Mixer</text>
    </svg>
  )
}
// const SmartphoneSVG = ({scale}) => {
//   return(
//     <svg width={scale ? "100" : "200"} height={scale ? "100" : "330"} viewBox="0 0 200 330" xmlns="http://www.w3.org/2000/svg">
//       <rect x="30" y="60" width="140" height="260" rx="20" ry="20" fill="black" />
//       <rect x="40" y="80" width="120" height="200" rx="10" ry="10" fill="lightblue" />
//       <circle cx="100" cy="300" r="10" fill="darkgray" />
//         <text x="50%" y="30" fill="black" textAnchor="middle" fontSize="33" fontFamily="Arial" dy=".3em">スマートフォン</text>
//     </svg>
//   )
// }

const NintendoSwitchSVG = ({scale}) => {
  return (
    <svg width={scale ? "100" : "300"} height={scale ? "100" : "200"} viewBox="0 0 300 200" xmlns="http://www.w3.org/2000/svg">
      <rect x="20" y="50" width="80" height="120" rx="30" ry="30" fill="blue" />
      <rect x="220" y="50" width="80" height="120" rx="30" ry="30" fill="red" />
      <rect x="60" y="50" width="200" height="120" fill="black" />
      <rect x="70" y="60" width="180" height="100" rx="10" ry="10" fill="lightblue" />
        <text x="50%" y="20" fill="black" textAnchor="middle" fontSize="33" fontFamily="Arial" dy=".3em">Nintendo Switch</text>
    </svg>
  );
};

const PS5SVG = ({scale}) => {
  return (
    <svg width={scale ? "100" : "100"} height={scale ? "100" : "200"} viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
      <rect x="20" y="150" width="60" height="20" rx="8" ry="10" fill="black" />
      <path d="M 35,25 L 40,30 C 40,30 55,70 45,165 C 45,165 35,165 35,155 L 35,155 C 35,160 40,70 35,25 Z" fill="white" stroke="black"/>
      <path d="M 65,25 L 60,30 C 60,30 45,70 55,165 C 55,165 65,165 65,155 L 65,155 C 65,160 60,70 65,25 Z" fill="white" stroke="black"/>
      <path d="M 40,30 L 60,30 C 60,30 55,70 55,160 L 55,160 L 45,160 C 45,160 45,70 40,30 Z" fill="black"/>
      <text x="50%" y="10" fill="black" textAnchor="middle" fontSize="20" fontFamily="Arial" dy=".3em">PS5</text>
    </svg>
    
  );
};

const HeadphoneSVG = ({scale}) => {
  return (
    <svg width={scale ? "100" : "100"} height={scale ? "100" : "100"} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <path d="M 20,50 Q 20,20 50,20 Q 80,20 80,50" fill="white" stroke="black" />
      <rect x="20" y="50" width="15" height="30" rx="5" ry="5" fill="black" stroke="white" />
      <rect x="65" y="50" width="15" height="30" rx="5" ry="5" fill="black" stroke="white" />
      <rect x="25" y="75" width="10" height="10" rx="3" ry="3" fill="white" stroke="black" />
      <rect x="65" y="75" width="10" height="10" rx="3" ry="3" fill="white" stroke="black" />
      <text x="50%" y="10" fill="black" textAnchor="middle" fontSize="10" fontFamily="Arial" dy=".3em">ヘッドホン</text>
    </svg>
  );
};

const SpeakerSVG = ({scale}) => {
  return (
    <svg width={scale ? "100" : "200"} height={scale ? "100" : "150"} viewBox="0 0 200 150" xmlns="http://www.w3.org/2000/svg">
      <rect x="20" y="30" width="60" height="110" fill="black" rx="10" ry="10"/>
      <rect x="110" y="30" width="60" height="110" fill="black" rx="10" ry="10"/>
      <text x="50%" y="10" fill="black" textAnchor="middle" fontSize="20" fontFamily="Arial" dy=".3em">スピーカー</text>
    </svg>    
  );
};

const CableSVG = ({type, x1, y1, x2, y2}) => {
  let color;
  switch (type) {
      case 'HDMI':
          color = 'blue';
          break;
      case 'イヤホンジャック':
          color = 'green';
          break;
      default:
          color = 'black';
  }
  
  return (
      <>
          <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={color} strokeWidth="2" />
          <text x={(x1 + x2) / 2} y={(y1 + y2) / 2} fill={color} fontSize="10" fontFamily="Arial" textAnchor="middle">
              {type}
          </text>
      </>
  );
};

const PartSVG = ({ devices }) => {
  const filteredDevices = useMemo(() => {
    const consoles = devices.filter(device => device.setID === 1 && device.checked);
    const outputVideos = devices.filter(device => device.setID === 2 && device.checked).flatMap(device => {
      return device.ID === 9 ? [device, device] : [device];
    });
    const outputAudios = devices.filter(device => device.setID === 3 && device.checked);
    
    return { consoles, outputVideos, outputAudios };
  }, [devices]);

  const getTransform = (setID, index) => {
    if(filteredDevices.consoles.length === 1){
      switch (setID) {
        case 1: 
          return { transform: "translate(250, 50)", x: 250, y: 50 }; // 中央上
        case 2: 
          return { transform: "translate(250, 200)", x: 250, y: 200 }; // 中央
        case 3: 
          return { transform: "translate(250, 350)", x: 250, y: 350 }; // 中央下
        default: 
          return { transform: "translate(0, 0)", x: 0, y: 0 };
      }
    }else{
      switch (setID) {
        case 1: 
          if (index === 0) return { transform: "translate(100, 50)", x: 100, y: 50 }; // 上部左上
          if (index === 1) return { transform: "translate(400, 50)", x: 400, y: 50 }; // 上部右上
          break;
        case 2: 
          if (index === 0) return { transform: "translate(100, 200)", x: 100, y: 200 }; // 中央左
          if (index === 1) return { transform: "translate(400, 200)", x: 400, y: 200 }; // 中央右
        break;
        case 3: 
          return { transform: "translate(250, 300)", x: 250, y: 300 }; // 中央下
        default: 
          return { transform: "translate(0, 0)", x: 0, y: 0 };
      }
    }
  };

  const positions = useMemo(() => {
    const pos = [];
    const addPositions = (devices, setID) => {
      devices.forEach((device, index) => {
        const transform = getTransform(setID, index);
        pos.push({ ...transform, setID });
      });
    };
    addPositions(filteredDevices.consoles, 1);
    addPositions(filteredDevices.outputVideos, 2);
    addPositions(filteredDevices.outputAudios, 3);
    return pos;
  }, [filteredDevices.consoles, filteredDevices.outputVideos, filteredDevices.outputAudios]);

  const renderDevice = (device, key) => {
    switch (device.device) {
      case "Nintendo Switch":
        return <NintendoSwitchSVG key={key} scale={true} />;
      case "PS5":
        return <PS5SVG key={key} scale={true} />;
      case "テレビ":
        return <TelevisionSVG key={key} scale={true} />;
      case "モニター":
      case "モニター(2枚)":
        return <MonitorSVG key={key} scale={true} />;
      case "外付けスピーカー":
        return <SpeakerSVG key={key} scale={true} />;
      case "イヤホン(ヘッドセット)":
        return <HeadphoneSVG key={key} scale={true} />;
      default:
        return null;
    }
  };

  const lines = [];

  // Line from setID 1 index 0 to setID 2 index 0
  if (filteredDevices.consoles.length > 1) {
    lines.push(
      <line key={`line-1-0-to-2-0`} x1={positions[0].x + 50}  y1={positions[0].y + 50} x2={positions[2].x + 50} y2={positions[2].y + 50} stroke="green" />
    );
  }else{
    lines.push(
      <line key={`line-1-0-to-2-0`} x1={positions[0].x + 50}  y1={positions[0].y + 50} x2={positions[1].x + 50} y2={positions[1].y + 50} stroke="green" />
    );
  }

  // Line from setID 1 index 1 to setID 2 index 1
  if (filteredDevices.consoles.length > 1) {
    lines.push(
      <line key={`line-1-1-to-2-1-1`} x1={positions[1].x + 50} y1={positions[1].y + 50} x2={positions[3].x + 50} y2={positions[3].y + 50} stroke="green" />
    );
  }

  // Line from setID 2 index 0 to setID 3
  if (filteredDevices.consoles.length > 1) {
    if (positions.length > 4) {
      lines.push(
        <line key={`line-2-0-to-3`} x1={positions[2].x + 50} y1={positions[2].y + 50} x2={positions[4].x -100} y2={positions[4].y + 50} stroke="red" />
      );
    }
    lines.push(
      <line key={`line-2-0-to-3-2`} x1={positions[4].x + 50} y1={positions[4].y + 50} x2={positions[4].x -100} y2={positions[4].y + 50} stroke="red" />
    );
  }else{
    lines.push(
      <line key={`line-2-0-to-3-1`} x1={positions[1].x + 50} y1={positions[1].y + 50} x2={positions[2].x + 50} y2={positions[2].y + 50} stroke="red" />
    );
  }

  // Line from setID 2 index 1 to setID 3
  if (filteredDevices.consoles.length > 1) {
    if (positions.length > 4) {
      lines.push(
        <line key={`line-2-1-to-3`} x1={positions[3].x + 50} y1={positions[3].y + 50} x2={positions[4].x + 200} y2={positions[4].y + 50} stroke="red" />
      );
      lines.push(
        <line key={`line-1-1-to-2-1-2`} x1={positions[4].x + 50} y1={positions[4].y + 50} x2={positions[4].x + 200} y2={positions[4].y + 50} stroke="red" />
      );
    }
  }

  return (
    <div>
      <svg width="500" height="500" xmlns="http://www.w3.org/2000/svg">
        {lines}
        {filteredDevices.outputVideos.map((device, index) => {
            const pos = getTransform(1, index);
          return (
            <g key={device.ID} transform={pos.transform}>
              {renderDevice(device, device.ID)}
            </g>
          );
        })}
        {filteredDevices.consoles.map((device, index) => {
          const pos = getTransform(2, index);
          return (
            <g key={device.ID} transform={pos.transform}>
              {renderDevice(device, device.ID)}
            </g>
          );
        })}
        {filteredDevices.outputAudios.map((device, index) => {
          const pos = getTransform(3, index);
          return (
            <g key={device.ID} transform={pos.transform}>
              {renderDevice(device, device.ID)}
            </g>
          );
        })}
      </svg>
    </div>
  );
};



export default PartSVG;