import { useState } from 'react';
import './App.css';
import PartSVG from './PartSVG';

function App() {

  const labels = [
    '配線を開始する',
    '接続するコンソールを選択してください(2つまで選択可)',
    '映像の出力先を選択してください(1つのみ選択可)',
    '音声の出力先を選択してください(1つのみ選択可)',
    // '合成する音声を選択してください(複数選択可)',
    'このような配線にしてね'
  ];

  const [devices, setDevices] = useState([
    {ID:1, setID:1, device:"Nintendo Switch", checked:false},
    {ID:2, setID:1, device:"PS5", checked:false},
    // {ID:3, setID:1, device:"PS4", checked:false},
    // {ID:4, setID:1, device:"XBOX Series X", checked:false},
    // {ID:5, setID:1, device:"XBOX Series S", checked:false},
     {ID:6, setID:1, device:"PC", checked:false},
    // {ID:5, setID:1, device:"スマートフォン", checked:false},
    // {ID:6, setID:1, device:"テレビ", checked:false},
    {ID:7, setID:2, device:"テレビ", checked:false},
    {ID:8, setID:2, device:"モニター", checked:false},
    {ID:9, setID:2, device:"モニター(2枚)", checked:false},
    {ID:10, setID:3, device:"テレビ", checked:false},
    {ID:11, setID:3, device:"モニター", checked:false},
    {ID:12, setID:3, device:"外付けスピーカー", checked:false},
    {ID:13, setID:3, device:"イヤホン(ヘッドセット)", checked:false},
    // {ID:13, setID:3, device:"スマートフォン", checked:false},
    // {ID:14, setID:4, device:"PS4", checked:false},
    // {ID:15, setID:4, device:"PS5", checked:false},
    // {ID:16, setID:4, device:"Nitendo Switch", checked:false},
    // {ID:17, setID:4, device:"PC", checked:false},
    // {ID:18, setID:4, device:"テレビ", checked:false},
    // {ID:19, setID:4, device:"マイク", checked:false},
    // {ID:20, setID:4, device:"スマートフォン", checked:false},
  ]);

  const SetDeviceList = ({currentIndex}) => {
    //currentDeviceList = devices.filter(device => device.setID === currentIndex)
    const currentDeviceList = devices.filter(device => {
      if( device.setID === 3 && currentIndex === 3 ){
        return !isDeviceChecked(2, device.device);
      }else{
        return device.setID === currentIndex;
    }})
    
    const handleDeviceClick = (id) => {
      const clickedDevice = devices.find(device => device.ID === id);
      const isSetID1 = clickedDevice.setID === 1;
      const checkedDevicesSetID1 = devices.filter(device => device.setID === 1 && device.checked);
    
      // チェックボックスを反転する関数
      const toggleDeviceChecked = () => {
        const updatedDevices = devices.map(device => 
          device.ID === id ? { ...device, checked: !device.checked } : device
        );
        setDevices(updatedDevices);
        setShowErrorMessage2(false);
      };
    
      if (isSetID1) {
        if (checkedDevicesSetID1.length > 1) {
          if (checkedDevicesSetID1.length === 2 && clickedDevice.checked) {
            toggleDeviceChecked();
          } else if (!showErrorMessage2) {
            setShowErrorMessage2(true);
          }
        } else {
          toggleDeviceChecked();
        }
      } else if ([2, 3].includes(clickedDevice.setID)) {
        const updatedDevices = devices.map(device => ({
          ...device,
          checked: device.setID === clickedDevice.setID ? false : device.checked
        })).map(device => 
          device.ID === id ? { ...device, checked: !clickedDevice.checked } : device
        );
        setDevices(updatedDevices);
        setShowErrorMessage2(false);
      } else {
        toggleDeviceChecked();
      }
    };

    return(
      <div className="center-container">
      <div className="device-item-container">
      {currentDeviceList.map((device) => (
        <div key={device.ID} className="device-item">
          <input 
            type="checkbox"
            id={`device-${device.ID}`} 
            checked = {device.checked} 
            readOnly 
            onChange={() => handleDeviceClick(device.ID)}
            />
          <label htmlFor={`device-${device.ID}`}>{device.device}</label>
        </div>
      ))}
    </div>
  </div>
    )
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showErrorMessage1, setShowErrorMessage1] = useState(false);
  const [showErrorMessage2, setShowErrorMessage2] = useState(false);

  const handleNext = () => {
    if(currentIndex > 0 && !devices.some(device => device.setID === currentIndex && device.checked)){
      setShowErrorMessage1(true);
    }else{
      setShowErrorMessage1(false);
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, labels.length - 1));
    }
  };

  const handleBack = () => {
    setShowErrorMessage1(false);
    setCurrentIndex((prevIndex) => Math.min(prevIndex - 1, labels.length - 1));
  };

  const handleDecision = () =>{
    if(!devices.some(device => device.setID === currentIndex && device.checked)){
      setShowErrorMessage1(true);
    }else{
      setShowErrorMessage1(false);
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, labels.length - 1));
    }
    
  }

  const isDeviceChecked = (setID, deviceName) => {
    return devices.some(device => device.setID === setID && device.device === deviceName && !device.checked);
  };

  const handleReset = () => {
    setCurrentIndex(0);
  };

  const renderIntro = () => (
    <>
      <h3>もう配線に迷わない。簡単接続でスッキリ解決！</h3>
      <h5>当サイトは、ゲーム機器やPCをイヤホンやヘッドホンに繋ぐ際の複雑な配線をシンプルにすることを目的としています。</h5>
      <h5>初心者でも簡単に、配線の接続を手助けします。</h5>
    </>
  );

  const renderButton = () => {
    if (currentIndex === 0) return <button onClick={handleNext}>開始</button>;
    if (currentIndex > 0 && currentIndex < labels.length - 2) return <button onClick={handleNext}>次へ</button>;
    if (currentIndex === labels.length - 2) return <button onClick={handleDecision}>決定</button>;
    if (currentIndex === labels.length - 1) return (
      <>
        <PartSVG devices={devices} />
        <button onClick={handleReset}>最初からやり直す</button>
        <button onClick={handleReset}>画像を保存する</button>
      </>
    );
    return null;
  };
  
  const renderErrorMessages = () => (
    <>
      {showErrorMessage1 && <div><p><font color="red">選択肢の中から1つを選択してください</font></p></div>}
      {showErrorMessage2 && <div><p><font color="red">2つまで選択可能です</font></p></div>}
    </>
  );
  
  return (
    <div className='App'>
      <h1>はいせんス</h1>
      {currentIndex === 0 && renderIntro()}
      <div>{labels[currentIndex]}</div>
      <SetDeviceList currentIndex={currentIndex} />
      {renderButton()}
      {currentIndex > 0 && <button onClick={handleBack}>戻る</button>}
      {renderErrorMessages()}
    </div>
  );
}

export default App;